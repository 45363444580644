<template lang="pug">
  .car-class-form
    .arrow-back(@click="handleClickBack")
      FaIcon(
        class="fa-rotate-270"
        :icon="'chevron-up'"
      )
      span {{ $t("company_system.back") }}
    .title
      span {{ $t("company_system.car_class_settings.car_class_form.details") }}
    .form
      .form-row.ml-0.mr-0
        .form-row-label
          FormFieldLabel(:title="$t('company_system.car_class_settings.car_class_form.code')")
        .form-row-field
          BFormInput(
            type="text"
            name="code"
            v-model="carClass.code"
            :placeholder="$t('company_system.car_class_settings.car_class_form.code')"
          )
      .form-row.ml-0.mr-0
        .form-row-label
          FormFieldLabel(:title="$t('company_system.car_class_settings.car_class_form.name')")
          span.required {{ $t('company_system.req') }}
        .form-row-field
          BFormInput(
            type="text"
            name="name"
            v-model="carClass.name"
            :class="{ invalid: $v.carClass.name.$error }"
            :placeholder="$t('company_system.car_class_settings.car_class_form.name')"
          )
      .form-row.ml-0.mr-0
        .form-row-label
          FormFieldLabel(:title="$t('company_system.car_class_settings.car_class_form.type')")
          span.required {{ $t('company_system.req') }}
        .form-row-field
          AppDropdown(
            close-on-select
            allow-empty
            value-key="id"
            title-key="name"
            :value="selectedCarType"
            :items="translatedCarTypes"
            :class="{ invalid: $v.carClass.companies_car_type_id.$error }"
            @select="selectCarType"
          )
      .form-row.ml-0.mr-0
        .form-row-label
          FormFieldLabel(:title="$t('company_system.car_class_settings.car_class_form.model')")
          span.required {{ $t('company_system.req') }}
        .form-row-field
          AppDropdown(
            searchable
            close-on-select
            multiple
            removable-labels
            allow-empty
            value-key="id"
            title-key="name"
            :value="selectedCarModels"
            :items="carModels"
            :class="{ invalid: $v.carClass.companies_car_model_ids.$error }"
            @select="selectCarModels"
          )
      .form-row.ml-0.mr-0
        .form-row-label
          FormFieldLabel(:title="$t('company_system.car_class_settings.car_class_form.image')")
        .form-row-field
          img(
            v-if="carClass.image_url"
            :src="carClass.image_url"
          )
          BFormFile(
            v-else
            accept="image/jpeg, image/png, image/gif"
            name="image-file"
            @input="handleFileUpload"
          )
        AppIconButton.delete(
          icon="trash-alt"
          title="company_system.delete"
          @click="handleFileDelete"
        )
      .form-row.ml-0.mr-0
        .form-row-label
          FormFieldLabel(:title="$t('company_system.car_class_settings.car_class_form.image_caption')")
        .form-row-field
          BFormInput(
            type="text"
            name="image-caption"
            v-model="carClass.image_title"
            :placeholder="$t('company_system.car_class_settings.car_class_form.image_caption')"
          )
          span.text-length
            | {{ imageCaptionLengthLabel }}
      .form-row.ml-0.mr-0
        .form-row-label
          FormFieldLabel.transmission(:title="$t('company_system.car_class_settings.car_class_form.transmission.label')")
          span.required {{ $t('company_system.req') }}
        .form-row-field
          .transmissions.flex-row
            .transmissions-radio.flex-row(
              v-for="transmission in transmissions"
            )
              AppRadioButton(
                :value="carClass.transmission === transmission"
                @change="selectTransmission(transmission)"
              )
              .label
                span {{ $t(`company_system.car_class_settings.car_class_form.transmission.${transmission}`) }}
      .form-row.ml-0.mr-0
        .form-row-label
          FormFieldLabel(:title="$t('company_system.car_class_settings.car_class_form.riding_capacity')")
          span.required {{ $t('company_system.req') }}
        .form-row-field.riding-capacity.flex-row
          AppNumberInput(
            name="riding-capacity"
            :invalid="$v.carClass.capacity.$error"
            v-model="carClass.capacity"
          )
          .label
            span {{ $t('company_system.car_class_settings.car_class_form.persons') }}
      .form-row.ml-0.mr-0
        .form-row-label
          FormFieldLabel(:title="$t('company_system.car_class_settings.car_class_form.engine_capacity')")
          span.required {{ $t('company_system.req') }}
        .engine-capacity.flex-row
          .form-row-field.engine-capacity-field.flex-row
            AppNumberInput(
              :max="10000"
              name="displacement-from"
              :invalid="$v.carClass.displacement.from.$error"
              v-model="carClass.displacement.from"
            )
            .label
              span {{ $t('company_system.car_class_settings.car_class_form.engine_measurement') }}
          .engine-capacity-delimiter
            span ~
          .form-row-field.engine-capacity-field.flex-row
            AppNumberInput(
              :max="10000"
              name="displacement-to"
              :invalid="$v.carClass.displacement.to.$error"
              v-model="carClass.displacement.to"
            )
            .label
              span {{ $t('company_system.car_class_settings.car_class_form.engine_measurement') }}
        .form-row.ml-0.mr-0
          .form-row-label
            FormFieldLabel(:title="$t('company_system.car_class_settings.car_class_form.non_smoking')")
          .form-row-field.non-smoking.flex-row
            AppCheckbox(
              v-model="carClass.non_smoking"
              @change="$emit('change-non-smoking')"
            )
            .label
              span {{ $t('company_system.car_class_settings.car_class_form.non_smoking') }}
        .form-row.ml-0.mr-0
          .form-row-label
            FormFieldLabel(:title="$t('company_system.car_class_settings.car_class_form.carrying_capacity.label')")
          .carrying-capacity.flex-row
            .form-row-field.carrying-capacity-field.flex-row
              .label
                span {{ $t('company_system.car_class_settings.car_class_form.carrying_capacity.large_suitcase') }}
              AppNumberInput(
                allow-null
                name="carrying-capacity-large-suitcase"
                v-model="carClass.carrying_capacity.large_suitcase"
              )
            .form-row-field.carrying-capacity-field.flex-row
              .label
                span {{ $t('company_system.car_class_settings.car_class_form.carrying_capacity.small_suitcase') }}
              AppNumberInput(
                allow-null
                name="carrying-capacity-small-suitcase"
                v-model="carClass.carrying_capacity.small_suitcase"
              )
            .form-row-field.carrying-capacity-field.flex-row
              .label
                span {{ $t('company_system.car_class_settings.car_class_form.carrying_capacity.travel_bag') }}
              AppNumberInput(
                allow-null
                name="carrying-capacity-travel-bag"
                v-model="carClass.carrying_capacity.travel_bag"
              )
        .form-row.ml-0.mr-0
          .form-row-label
            FormFieldLabel(:title="$t('company_system.car_class_settings.car_class_form.explanation')")
          .form-row-field.explanation
            textarea(
              :rows="2"
              :maxlength="1024"
              :value="carClass.description"
              :class="{ 'invalid': $v.carClass.description.$error }"
              :placeholder="$t('company_system.car_class_settings.car_class_form.explanation')"
              @input="handleDescriptionInput"
            )
            span.text-length(
              :class="{ 'invalid': !$v.carClass.description.maxLength }"
            )
              | {{ explanationLengthLabel }}
    FormActionBar(
      :delete-enabled="isEdit && carClass.removable"
      @delete="removeCarClass"
      @save="handleSave"
    )
</template>

<script>
  const IMAGE_CAPTION_MAX_LENGTH = 100
  const EXPLANATION_CAPTION_MAX_LENGTH = 128
  const TRANSMISSIONS = ["automatic", "manual", "unspecified"]

  // misc
  import { SERIALIZER_VIEW_SHORT } from "@/config/constants"
  import { defaultCarClassObject } from "./helpers"
  import { extractTranslatedAttribute } from "@/helpers/common"
  import { map, find, filter, cloneDeep, isEqual } from "lodash-es"

  // store modules
  import carClassSettingsModule from "@/config/store/company_system/car_class_settings/car_classes"
  import carTypesModule from "@/config/store/company_system/car_class_settings/car_types"
  import carModelsSettingsModule from "@/config/store/company_system/car_class_settings/car_models"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withValidations from "@/mixins/withValidations"
  import withScrollTop from "@/mixins/withScrollTop"
  import withConfirmation from "@/mixins/withConfirmation"

  const carTypesMixin = withStoreModule(carTypesModule, {
    name: "carTypesMixin",
    readers: { carTypes: "items" },
    actions: { fetchCarTypes: "FETCH_ITEMS" }
  })

  const carModelsSettingsMixin = withStoreModule(carModelsSettingsModule, {
    name: "carModelSettingsMixin",
    readers: { carModels: "items" },
    actions: { fetchCarModels: "FETCH_ITEMS" }
  })

  const carClassSettingsMixin = withStoreModule(carClassSettingsModule, {
    resetState: true,
    name: "carClassSettingsMixin",
    readers: {
      carClassItem: "item"
    },
    actions: {
      fetchCarClass: "FETCH_ITEM",
      createCarClass: "CREATE_ITEM",
      updateCarClass: "UPDATE_ITEM",
      deleteCarClass: "DELETE_ITEM"
    },
    mutations: { setCarClass: "SET_ITEM" }
  })

  const validationsMixin = withValidations(({ required, integer, minValue, minLength, maxLength }) => ({
    carClass: {
      name: {
        required,
        minLength: minLength(1)
      },
      companies_car_type_id: { required },
      companies_car_model_ids: {
        required,
        minLength: value => value.length > 0
      },
      transmission: { required },
      capacity: {
        required,
        minValue: minValue(1),
        integer
      },
      displacement: {
        from: {
          required,
          minValue: minValue(1),
          integer
        },
        to: {
          required,
          minValue: minValue(1),
          integer
        }
      },
      description: {
        maxLength: maxLength(EXPLANATION_CAPTION_MAX_LENGTH)
      }
    }
  }))

  export default {
    components: {
      FormActionBar: () => import("../../FormActionBar"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppRadioButton: () => import("@/components/elements/AppRadioButton"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppCheckbox: () => import("@/components/elements/AppCheckbox"),
      AppNumberInput: () => import("@/components/elements/AppNumberInput")
    },

    mixins: [
      carTypesMixin,
      carClassSettingsMixin,
      carModelsSettingsMixin,
      validationsMixin,
      withScrollTop,
      withConfirmation
    ],

    props: {
      carClassId: {
        type: Number,
        required: false
      }
    },

    watch: {
      hasUnsavedChanges(useConfirm) {
        this.setLogoutConfirm(useConfirm)
      }
    },

    data() {
      return {
        file: null,
        carClass: defaultCarClassObject(),
        initialCarClass: {},
        transmissions: TRANSMISSIONS
      }
    },

    mounted() {
      this.fetchCarModels({
        pagination: { _disabled: true },
        serializer_view: SERIALIZER_VIEW_SHORT
      })
      if (this.isEdit) {
        this.fetchCarClass(this.carClassId).then(() => {
          this.carClass = cloneDeep(this.carClassItem)
          this.initialCarClass = cloneDeep(this.carClassItem)
        })
      } else {
        this.initialCarClass = defaultCarClassObject()
      }
    },

    methods: {
      handleClickBack() {
        this.$conditionalConfirm({
          useConfirm: this.hasUnsavedChanges,
          handler: () => this.$emit("back")
        })
      },

      handleDescriptionInput({ target: { value } }) {
        this.carClass.description = value === "" ? null : value
      },

      handleFileUpload(file) {
        this.file = file
        this.carClass.image_url = URL.createObjectURL(file)
      },

      handleFileDelete() {
        this.file = null
        this.carClass.image_url = null
        this.carClass.image_removed = true
      },

      selectCarType({ id }) {
        this.carClass.companies_car_type_id = id
      },

      selectCarModels(models) {
        this.carClass.companies_car_model_ids = map(models, "id")
      },

      selectTransmission(transmission) {
        this.carClass.transmission = transmission
      },

      textLength(length, maxLength) {
        return `(${length}/${maxLength})`
      },

      handleSave() {
        this.validateAttributes()
        if (!this.isValid) {
          this.$nextTick(() => {
            this.scrollTo({ target: ".invalid", inline: "center" })
          })
          return
        }
        this.sendRequest().then(() => {
          this.cancelValidation()
          this.$emit("back")
        })
      },

      buildFormData() {
        const formData = new FormData()
        if (this.file) {
          formData.append("image", this.file)
        }
        formData.append("car_class", JSON.stringify(this.carClass))
        return formData
      },

      async sendRequest() {
        const formData = this.buildFormData()
        if (this.isEdit) {
          await this.updateCarClass({ id: this.carClass.id, formData })
        } else {
          await this.createCarClass(formData)
        }
      },

      removeCarClass() {
        this.$confirm({
          title: this.$t("company_system.car_class_settings.delete_car_class_confirmation"),
          resolve: {
            handler: () => {
              this.deleteCarClass(this.carClassId).then(() => {
                this.$emit("back")
              })
            }
          }
        })
      }
    },

    computed: {
      isEdit() {
        return !!this.carClassId
      },

      hasUnsavedChanges() {
        return !isEqual(this.carClass, this.initialCarClass)
      },

      selectedCarType() {
        return find(this.translatedCarTypes, { id: this.carClass.companies_car_type_id })
      },

      selectedCarModels() {
        return filter(this.carModels, ({ id }) => this.carClass.companies_car_model_ids.includes(id))
      },

      translatedCarTypes() {
        return map(this.carTypes, carType => {
          return {
            id: carType.id,
            name: extractTranslatedAttribute(carType, "type_name")
          }
        })
      },

      imageCaptionLength() {
        return this.carClass.image_title?.length || 0
      },

      imageCaptionLengthLabel({ imageCaptionLength }) {
        return this.textLength(imageCaptionLength, IMAGE_CAPTION_MAX_LENGTH)
      },

      explanationLength() {
        return this.carClass.description?.length || 0
      },

      explanationLengthLabel({ explanationLength }) {
        return this.textLength(explanationLength, EXPLANATION_CAPTION_MAX_LENGTH)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/matchings.sass"
  @import "@/assets/styles/mixins/common.sass"

  .car-class-form
    margin-bottom: 20px

    .flex-row
      display: flex
      justify-content: left

    .arrow-back
      cursor: pointer
      margin-left: 5px
      margin-top: 10px

      ::v-deep
        svg
          path
            fill: $default-gray-medium

      span
        vertical-align: text-top
        margin-left: 5px
        color: $default-purple
        font-size: 0.9rem

    .title
      margin-top: 20px
      height: 40px
      border-left: 13px solid $default-purple-light
      padding: 10px
      font-weight: 700
      font-size: 16px

    .form
      padding: 5px

      .app-overlay-loader
        position: fixed

      &-row
        display: flex
        flex-direction: column
        margin-top: 20px

        button.delete
          margin-top: 10px
          width: 120px
          border-color: $default-red !important

          ::v-deep
            .app-button-content
              color: $default-red

        .transmissions
          &-radio
            font-weight: 300
            margin-right: 10px

            .app-radio-button
              padding-top: 2px

            .label
              margin-left: 5px

        &-label
          font-style: normal
          font-weight: 200
          font-size: 13px
          line-height: 19px

          span.required
            margin-left: 10px
            font-size: 12px
            color: $default-red

          span.transmission
            font-weight: 500

        &-field
          margin-top: 5px
          font-weight: 400
          font-size: 16px

          img
            width: 300px

          ::v-deep
            input
              border-color: $default-purple-light-line

              &.invalid
                +default-invalid-input

          .app-select
            padding: 0

            &.invalid
              +default-invalid-input

          .text-length
            font-weight: 500
            font-size: 12px
            color: $default-gray-medium

            &.invalid
              color: $default-red

          &.explanation
            display: flex
            flex-direction: column

            textarea
              width: 100%
              margin-bottom: 5px
              border: 1px solid $default-purple-light
              border-radius: 5px
              padding-left: 5px

              &.invalid
                +default-invalid-input

              &::placeholder
                color: $default-gray-medium

        .riding-capacity
          ::v-deep
            .app-number-input
              input
                height: 38px
                width: 40px

          .label
            padding: 7px
            font-size: 15px

        .engine-capacity
          &-field
            .label
              padding: 7px
              font-size: 15px

            ::v-deep
              .app-number-input
                input
                  height: 38px
                  width: 60px

          &-delimiter
            margin-right: 5px
            font-size: 30px
            font-weight: 100

        .carrying-capacity
          &-field
            margin-right: 5px

            .label
              padding: 7px
              font-size: 15px

            ::v-deep
              .app-number-input
                input
                  height: 38px
                  width: 40px

        .non-smoking
          .app-checkbox
            padding-right: 0

          .label
            padding: 7px
            font-size: 15px

        &-image
          margin-top: 5px
          width: 277px
          height: 180px
          border: 1px dashed $default-gray-medium
          border-radius: 5px
          display: flex
          justify-content: center
          align-items: center
          color: $default-gray
</style>
